import { reactive } from 'vue'

export const useFilters = (formData) => {
  let queryParams = reactive({})

  Object.keys(formData).forEach((key) => {
    if (key === 'filters' && formData[key]) {
      Object.keys(formData[key]).forEach((filterKey) => {
        const filter = formData[key][filterKey]
        if (filter.filterType === 'set' && filter.values) {
          queryParams[`${filterKey}[]`] = filter.values
        } else if (filter.filterType === 'text') {
          queryParams[filterKey] = filter.filter
        }
      })
    } else if (key === 'extra' && formData[key]) {
      Object.keys(formData[key]).forEach((filterKey) => {
        queryParams[filterKey] = formData[key][filterKey]
      })
    } else if (key === 'sort' && Array.isArray(formData[key])) {
      formData[key].forEach((sortItem) => {
        queryParams[`sort[${sortItem.colId}]`] = sortItem.sort
      })
    } else {
      queryParams[key] = formData[key]
    }
  })

  return queryParams
}
