import { useDayjs } from '#dayjs'
import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service.js'
import { useQuotesStore } from '~/store/quotes.js'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    access_token: null,
    token_type: 'Bearer',
    user: null,
  }),
  getters: {
    authorizationHeader() {
      return `${this.token_type} ${this.access_token}`
    },
    isAuthenticated() {
      return !!this.access_token
    },
  },
  actions: {
    async getMSLLink(formData) {
      return axiosService
        .get('/oauth/login-url', formData)
        .then((response) => {
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    async getAuthTokenWithMsal(formData) {
      return axiosService
        .post('/oauth/token', formData)
        .then((response) => {
          this.access_token = response.access_token
          this.token_type = response.token_type
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchUser() {
      return axiosService
        .get('/employees')
        .then((response) => {
          this.user = response

          const dayjs = useDayjs()
          const startOfCurrentMonth = dayjs('2024-06-01')

          const filters = {
            status: {
              values: [1, 3],
              filterType: 'set',
              columnKey: 'status',
            },
            created_at: {
              dateFrom: startOfCurrentMonth.format('YYYY-MM-DD'),
              dateTo: '',
              filterType: 'date',
              type: 'greaterThan',
              columnKey: 'created_at',
            },
          }

          useQuotesStore()
            .fetchQuoteStatsRequests({ filters })
            .catch(() => {
              ElMessage.error('Failed to fetch quote stats leads.')
            })

          return response
        })
        .catch((err) => {
          throw err
        })
    },
    async logout() {
      axiosService
        .post('/oauth/token/logout')
        .then(() => {
          this.access_token = null
          this.token_type = 'Bearer'
          this.user = null

          navigateTo('/login')
        })
        .catch((err) => {
          throw err
        })
    },
  },
  persist: true,
})
