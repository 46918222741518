import axios from 'axios'

function determineBaseURL(type, $baseURL) {
  const defaultBaseURL = $baseURL

  if (type === 'normal') {
    return import.meta.env.VITE_API_BASE || defaultBaseURL
  } else if (type === 'proclaim') {
    return import.meta.env.VITE_PROCLAIM_API_BASE || defaultBaseURL
  } else {
    return defaultBaseURL
  }
}

export function createBaseService(getHeadersFunction, $baseURL = 'https://pro.avrillo.co.uk/api', type = 'normal') {
  const service = axios.create({
    baseURL: determineBaseURL(type, $baseURL),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  service.interceptors.request.use(
    (config) => {
      config.headers = { ...config.headers, ...getHeadersFunction(config.data) }
      return config
    },
    (error) => Promise.reject(error),
  )

  service.interceptors.response.use(
    (response) => {
      return response.data?.meta ? response.data : response?.data?.data ?? response.data
    },
    (error) => {
      console.error(error, error?.response?.request?.responseURL)

      return Promise.reject(error)
    },
  )

  return service
}
