export const useSorts = (formData) => {
  const transformedData = { ...formData }

  if (Array.isArray(transformedData.sort)) {
    transformedData.sort.forEach((sortItem) => {
      transformedData[`sort[${sortItem.colId}]`] = sortItem.sort
    })
    delete transformedData.sort
  }

  return transformedData
}
