import { useAuthStore } from '@/store/auth.js'
import CryptoJS from 'crypto-js'

export function getRequestHeaders(data) {
  const content = !data || data instanceof FormData ? '' : JSON.stringify(data)

  return {
    Authorization: useAuthStore().isAuthenticated ? useAuthStore().authorizationHeader : undefined,
  }
}

export function getRequestHeadersProclaim(data) {
  const content = !data || data instanceof FormData ? '' : JSON.stringify(data)

  return {
    Signature: CryptoJS.HmacSHA256(`${content}`, import.meta.env?.VITE_APP_SIGNING_SECRET).toString(CryptoJS.enc.Hex),
    Authentication: btoa('username:password'),
  }
}
